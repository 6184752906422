'use client';

import { isEmpty } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import { ZIndexContext } from '../providers/ZIndexProvider';

const defaultZIndex = 500;

export const useZIndex = (enabled = true) => {
  const { zIndexes, addZIndex, removeZIndex } = useContext(ZIndexContext);

  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (zIndex === -1 && enabled) {
      const typeZIndexes = zIndexes.current;

      const nextZIndex = isEmpty(typeZIndexes)
        ? defaultZIndex
        : typeZIndexes[typeZIndexes.length - 1] + 1;

      addZIndex(nextZIndex);
      setZIndex(nextZIndex);
    }

    return () => {
      removeZIndex(zIndex);
    };
  }, [zIndex, enabled]);

  return zIndex;
};
