'use client';

import {
  FloatingFocusManager,
  FloatingOverlay,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useState } from 'react';
import { useZIndex } from '../hooks';
import { useRouteChangeHandler } from '../hooks/useRouteChangeHandler';
import { theme } from '../theme/theme.css';
import { BottomSheet } from './bottomSheet';
import { Modal } from './modal';
import { OverlayItem } from './types';
import { useOverlay } from './useOverlay';

interface OverlayProps extends OverlayItem {
  isActive: boolean;
}

export function Overlay({ id, Component, isActive }: OverlayProps) {
  const zIndex = useZIndex();
  const [isOpen, setIsOpen] = useState(true);
  const { closeOverlay } = useOverlay();

  const { component: ContentComponent, isBottomSheet, props } = Component;
  const OverlayComponent = isBottomSheet ? BottomSheet : Modal;

  const overlayStyle = props?.style?.overlay || {};
  const containerStyle = props?.style?.container || {};

  const onClose = () => closeOverlay(id);

  const { context, refs } = useFloating({
    open: isOpen,
    onOpenChange: isOpen => {
      setIsOpen(isOpen);

      if (!isOpen) {
        onClose();
      }
    },
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    outsidePress: isBottomSheet,
    outsidePressEvent: 'mousedown',
    escapeKey: isActive,
  });
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const labelId = useId();
  const descriptionId = useId();

  useRouteChangeHandler(onClose);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()} />
      <FloatingOverlay style={{ background: theme.surface.dim, zIndex }}>
        <FloatingFocusManager context={context}>
          <div
            ref={refs.setFloating}
            aria-labelledby={labelId}
            aria-describedby={descriptionId}
            {...getFloatingProps()}>
            <OverlayComponent
              onClose={onClose}
              containerStyle={containerStyle}
              overlayStyle={overlayStyle}
              shouldCloseOnOverlayClick={props?.shouldCloseOnOverlayClick}>
              <ContentComponent {...props} onClose={onClose} />
            </OverlayComponent>
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </>
  );
}
