import clsx from 'clsx';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import * as styles from './clearButton.css';

export interface ClearButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean;
}

export const ClearButton = forwardRef<HTMLButtonElement, ClearButtonProps>(
  (
    { type = 'button', children, className, fullWidth = false, ...props },
    forwardedRef
  ) => {
    return (
      <button
        ref={forwardedRef}
        type={type}
        className={clsx([styles.clearButton({ fullWidth }), className])}
        {...props}>
        {children}
      </button>
    );
  }
);
