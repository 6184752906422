'use client';

import { ChangeEvent, forwardRef, MouseEvent, ReactNode } from 'react';
import * as styles from './fileInput.css';

export interface FileInputProps {
  accept: string;
  id?: string;
  multiple?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
}

export const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  (
    { accept, id = 'fileUploader', onChange, children, multiple = false },
    forwardedRef
  ) => {
    const handleClick = (e: MouseEvent<HTMLInputElement>) => {
      // 같은 file을 선택하면 change 이벤트가 발생하지 않음.
      // value 값을 비워줌으로써 이전값과 다르게 인식하도록 처리.
      e.currentTarget.value = '';
    };

    return (
      <div>
        <input
          type="file"
          id={id}
          className={styles.input}
          accept={accept}
          onChange={onChange}
          onClick={handleClick}
          ref={forwardedRef}
          multiple={multiple}
        />
        <label htmlFor={id} className={styles.label}>
          {children}
        </label>
      </div>
    );
  }
);
