'use client';

import { createContext, MutableRefObject, ReactNode, useRef } from 'react';

interface TooltipContextType {
  zIndexes: MutableRefObject<number[]>;
  addZIndex: (zIndex: number) => void;
  removeZIndex: (zIndex: number) => void;
}

export const ZIndexContext = createContext({} as TooltipContextType);

export const ZIndexProvider = ({ children }: { children: ReactNode }) => {
  const zIndexes = useRef<number[]>([]);

  const addZIndex = (zIndex: number) => {
    zIndexes.current = [...zIndexes.current, zIndex];
  };

  const removeZIndex = (zIndex: number) => {
    zIndexes.current = zIndexes.current.filter(value => value !== zIndex);
  };

  const value = { zIndexes, addZIndex, removeZIndex };

  return (
    <ZIndexContext.Provider value={value}>{children}</ZIndexContext.Provider>
  );
};
