'use client';

import { isNil } from 'lodash-es';
import { createContext, useContext } from 'react';
import { UseDropdownReturn } from './useDropdown';

export type DropdownContextType = UseDropdownReturn;

export const DropdownContext = createContext<DropdownContextType>(
  {} as DropdownContextType
);

export const useDropdownContext = (): DropdownContextType => {
  const context = useContext(DropdownContext);

  if (isNil(context)) {
    throw new Error('<Dropdown /> 컴포넌트로 감싸세요.');
  }

  return context;
};
