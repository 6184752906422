import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { overlay } from '../overlay.css';
import * as styles from './bottomsheet.css';
import { useBottomSheetSwipeActions } from './useBottomSheetSwipeActions';

interface BottomSheetProps {
  children: ReactNode;
  onClose: () => void;
  containerStyle?: CSSProperties;
  overlayStyle?: CSSProperties;
  shouldCloseOnOverlayClick?: boolean;
  duration?: number;
}

export function BottomSheet({
  children,
  onClose,
  containerStyle,
  overlayStyle,
  shouldCloseOnOverlayClick = true,
  duration = 200,
}: BottomSheetProps) {
  const overlayRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    if (contentRef.current) {
      contentRef.current.style.transform = 'translateY(100%)';
    }
    setTimeout(onClose, duration);
  };

  useBottomSheetSwipeActions({
    duration,
    overlayRef,
    handleRef,
    contentRef,
    onSwipeClose: handleClose,
  });

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (shouldCloseOnOverlayClick && e.target === overlayRef.current) {
      handleClose();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (contentRef.current) {
        contentRef.current.style.transform = 'translateY(0)';
      }
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      ref={overlayRef}
      className={overlay}
      style={{
        transition: `opacity ${duration}ms ease-out`,
        opacity: isClosing ? 0 : 1,
        ...overlayStyle,
      }}
      onClick={handleOverlayClick}>
      <div
        ref={contentRef}
        className={styles.bottomSheet}
        style={{
          ...containerStyle,
          transform: 'translateY(100%)',
          transition: `transform ${duration}ms ease-out`,
        }}>
        <div ref={handleRef} className={styles.handle} />
        <div className={styles.contents}>{children}</div>
      </div>
    </div>
  );
}
