'use client';

import { forwardRef, useImperativeHandle, useState } from 'react';
import Cropper, { Area, Point } from 'react-easy-crop';
import { Element, ElementProps } from '../element';
import * as styles from './imageCropper.css';
import { getCroppedImageBlob } from './imageCropperUtil';

interface IImageCropperProps extends Pick<ElementProps, 'width' | 'height'> {
  defaultCrop?: Point;
  image: string;
  aspectRatio?: number;
}

export interface ImageCropper {
  getCroppedImageBlob: (outputSize?: {
    width: number;
    height: number;
  }) => Promise<Blob>;
}

const centerCropPoint: Point = { x: 0, y: 0 };

export const ImageCropper = forwardRef<ImageCropper, IImageCropperProps>(
  ({ defaultCrop = centerCropPoint, aspectRatio = 1, image }, forwardedRef) => {
    const [crop, setCrop] = useState(defaultCrop);
    const [cropArea, setCropArea] = useState<Area>({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    });

    const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
      setCropArea(croppedAreaPixels);
    };

    useImperativeHandle(forwardedRef, () => ({
      getCroppedImageBlob: outputSize =>
        getCroppedImageBlob(image, cropArea, outputSize),
    }));

    return (
      <Element width="100%" height="100%" position="relative">
        <Cropper
          classes={{
            containerClassName: styles.container,
            cropAreaClassName: styles.cropArea,
            mediaClassName: styles.media,
          }}
          image={image}
          crop={crop}
          aspect={aspectRatio}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          zoomWithScroll={false}
          showGrid={false}
        />
      </Element>
    );
  }
);
