'use client';

import { isServerSide } from '@vuddy/utils';
import { useEffect, useState } from 'react';

interface ScrollInfo {
  x: number;
  y: number;
  direction: 'none' | 'down' | 'up';
}
export const useScroll = (
  ignoreScrollEvent?: boolean
): [ScrollInfo, (top?: number, left?: number) => void] => {
  const [scroll, setScrollInfo] = useState<ScrollInfo>({
    x: 0,
    y: 0,
    direction: 'none',
  });
  const setScroll = (top?: number, left?: number) => {
    window.scrollTo({ top: top ?? scroll.y, left: left ?? scroll.x });
  };

  const onScroll = () => {
    setScrollInfo({
      x: window.scrollX,
      y: window.scrollY,
      direction:
        window.scrollY === scroll.y
          ? 'none'
          : window.scrollY > scroll.y
            ? 'down'
            : 'up',
    });
  };

  useEffect(() => {
    if (isServerSide || ignoreScrollEvent) return;

    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [ignoreScrollEvent]);

  return [scroll, setScroll];
};
