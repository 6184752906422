import { useMergeRefs } from '@floating-ui/react';
import { HTMLProps, cloneElement, forwardRef, isValidElement } from 'react';
import * as tooltipStyles from './tooltip.css';
import { useTooltipContext } from './useTooltipContext';

export interface ITooltipTriggerProps extends HTMLProps<HTMLElement> {
  asChild?: boolean;
}

export const TooltipTrigger = forwardRef<HTMLElement, ITooltipTriggerProps>(
  ({ children, asChild = false, ...props }, forwardedRef) => {
    const { open, refs, getReferenceProps } = useTooltipContext();
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([refs.setReference, forwardedRef, childrenRef]);
    const dataState = open ? 'open' : 'closed';

    if (asChild && isValidElement(children)) {
      return cloneElement(
        children,
        getReferenceProps({
          ref,
          ...props,
          ...children.props,
          'data-state': dataState,
        })
      );
    }

    return (
      <div
        className={tooltipStyles.triggerWrapper}
        ref={ref}
        data-state={dataState}
        {...getReferenceProps(props)}>
        {children}
      </div>
    );
  }
);
