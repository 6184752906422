import { isNil } from 'lodash-es';
import { Area } from 'react-easy-crop';

const loadImage = (imageUrl: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = document.createElement('img');
    image.src = imageUrl;
    image.onload = () => {
      resolve(image);
    };
    image.onerror = reject;
  });
};

const drawCroppedImage = (
  imageElement: HTMLImageElement,
  cropArea: Area,
  outputSize?: { width: number; height: number }
) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (isNil(context)) {
    new Error('Failed to get 2d context');
    return canvas;
  }

  const outputHeight = outputSize?.height ?? cropArea.height;
  const outputWidth = outputSize?.width ?? cropArea.width;
  canvas.width = outputWidth;
  canvas.height = outputHeight;

  context.drawImage(
    imageElement,
    cropArea.x,
    cropArea.y,
    cropArea.width,
    cropArea.height,
    0,
    0,
    outputWidth,
    outputHeight
  );

  return canvas;
};

export const getCroppedImageBlob = async (
  imageUrl: string,
  cropArea: Area,
  canvasSize?: { width: number; height: number }
) => {
  const imageElement = await loadImage(imageUrl);
  const croppedImageCanvas = drawCroppedImage(
    imageElement,
    cropArea,
    canvasSize
  );

  return new Promise<Blob>((resolve, reject) => {
    croppedImageCanvas.toBlob(blob => {
      if (!blob) {
        reject(new Error('Failed to get blob'));
        return;
      }

      resolve(blob);
    });
  });
};
