'use client';

import { isNonNil, jsonAssignInlineVars } from '@vuddy/utils';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { Element, ElementProps } from '../element';
import { GapToken, theme } from '../theme/theme.css';
import * as styles from './grid.css';

export type GridProps = {
  gap?: GapToken;
  gapRow?: GapToken;
  gapColumn?: GapToken;
  padding?: ElementProps['padding'];
  paddingX?: ElementProps['padding'];
  children?: ReactNode;
  repeat: number;
};

export const Grid = ({
  children,
  gap = 'gap_gapless',
  gapColumn,
  gapRow,
  repeat,
  ...props
}: GridProps) => {
  return (
    <Element
      className={clsx(
        styles.grid,
        isNonNil(repeat) ? styles.repeatCount : undefined
      )}
      style={jsonAssignInlineVars({
        [styles.gapRow]: theme.size[gapRow ?? gap],
        [styles.gapColumn]: theme.size[gapColumn ?? gap],
        [styles.repeatCountVar]: repeat,
      })}
      {...props}>
      {children}
    </Element>
  );
};
