type RGB = 'R' | 'G' | 'B';

const generateRgb = (hex: string) => (rgb: RGB) => {
  return hex.length === 4
    ? parseInt(
        rgb === 'R'
          ? hex.charAt(1) + hex.charAt(1)
          : rgb === 'G'
            ? hex.charAt(2) + hex.charAt(2)
            : hex.charAt(3) + hex.charAt(3),
        16
      )
    : parseInt(
        rgb === 'R'
          ? hex.slice(1, 3)
          : rgb === 'G'
            ? hex.slice(3, 5)
            : hex.slice(5, 7),
        16
      );
};

// https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
export const hexToRgba = (hex: string, alpha = 1) => {
  const convertHexToRgb = generateRgb(hex);
  const r = convertHexToRgb('R');
  const g = convertHexToRgb('G');
  const b = convertHexToRgb('B');

  return `rgba(${[r, g, b].join(',')},${alpha})`;
};
