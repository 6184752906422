'use client';

import {
  autoUpdate,
  flip,
  offset,
  OffsetOptions,
  Placement,
  useClick,
  useDismiss,
  useFloating,
  UseFloatingReturn,
  useInteractions,
} from '@floating-ui/react';
import { useMemo, useState } from 'react';

export interface UseDropdownOptions {
  initialOpen?: boolean;
  placement?: Placement;
  offset?: OffsetOptions;
  fallbackPlacements?: Placement[];
  open?: boolean;
  onChangeOpen?: (open: boolean) => void;
}

export interface UseDropdownReturn extends UseFloatingReturn {
  open: boolean;
  setOpen: (open: boolean) => void;
  getReferenceProps: ReturnType<typeof useInteractions>['getReferenceProps'];
  getFloatingProps: ReturnType<typeof useInteractions>['getFloatingProps'];
}

export const useDropdown = ({
  initialOpen = false,
  open: controlledOpen,
  onChangeOpen: setControlledOpen,
  placement = 'bottom-start',
  offset: offsetOptions,
  fallbackPlacements,
}: UseDropdownOptions): UseDropdownReturn => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const floating = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetOptions),
      flip({ fallbackPlacements, crossAxis: false }),
    ],
  });

  const { context } = floating;

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const interactions = useInteractions([click, dismiss]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...floating,
    }),
    [open, setOpen, interactions, floating]
  );
};
