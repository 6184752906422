import {
  OffsetOptions,
  Placement,
  UseFloatingReturn,
  autoUpdate,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { isNonNil } from '@vuddy/utils';
import { isNil } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';

export interface IUseTooltipOptions {
  initialOpen?: boolean;
  placement?: Placement;
  trigger?: 'hover' | 'click' | false;
  offsetOptions?: OffsetOptions;
  open?: boolean;
  autoClose?: number;
  onChangeOpen?: (open: boolean) => void;
}

export interface UseTooltipReturn extends UseFloatingReturn {
  open: boolean;
  setOpen: (open: boolean) => void;
  getReferenceProps: ReturnType<typeof useInteractions>['getReferenceProps'];
  getFloatingProps: ReturnType<typeof useInteractions>['getFloatingProps'];
}

export const useTooltip = ({
  initialOpen = false,
  trigger = 'hover',
  placement = 'top',
  offsetOptions = { mainAxis: 5, crossAxis: 0 },
  autoClose = 0,
  open: controlledOpen,
  onChangeOpen: setControlledOnChangeOpen,
}: IUseTooltipOptions): UseTooltipReturn => {
  const autoCloseRef = useRef<NodeJS.Timeout | null>(null);
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOnChangeOpen ?? setUncontrolledOpen;

  useEffect(() => {
    if (autoClose === 0 || !open) return;

    const clearAutoCloseTimer = () => {
      if (isNonNil(autoCloseRef.current)) {
        clearTimeout(autoCloseRef.current);
        autoCloseRef.current = null;
      }
    };

    clearAutoCloseTimer();

    autoCloseRef.current = setTimeout(() => setOpen(false), autoClose);

    return clearAutoCloseTimer;
  }, [autoClose, open, setOpen]);

  const floating = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(offsetOptions), shift({ padding: 16 })],
  });

  const { context } = floating;

  const hover = useHover(context, {
    enabled: isNil(controlledOpen) && trigger === 'hover',
  });

  const click = useClick(context, {
    enabled: isNil(controlledOpen) && trigger === 'click',
  });

  const dismiss = useDismiss(context);

  const role = useRole(context);

  const interactions = useInteractions([hover, click, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...floating,
    }),
    [open, setOpen, interactions, floating]
  );
};
