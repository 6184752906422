'use client';

import { usePathname } from 'next/navigation';
import { CSSProperties, ReactNode, useEffect, useRef } from 'react';
import { overlay } from '../overlay.css';
import * as styles from './modal.css';

interface ModalProps {
  children: ReactNode;
  onClose: () => void;
  containerStyle?: CSSProperties;
  overlayStyle?: CSSProperties;
  shouldCloseOnOverlayClick?: boolean;
}

export function Modal({
  children,
  onClose,
  overlayStyle,
  containerStyle,
  shouldCloseOnOverlayClick = false,
}: ModalProps) {
  const pathname = usePathname();
  const pathNameRef = useRef(pathname);

  const handleOverlayClick = () => {
    if (shouldCloseOnOverlayClick) {
      onClose();
    }
  };

  useEffect(() => {
    if (pathNameRef.current !== pathname) {
      pathNameRef.current = pathname;
      onClose();
    }
  }, [pathname]);

  return (
    <div className={overlay} style={overlayStyle} onClick={handleOverlayClick}>
      <div
        className={styles.modal}
        style={containerStyle}
        onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
}
