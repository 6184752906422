import {
  deleteCookie as nextDeleteCookie,
  getCookie as nextGetCookie,
  getCookies as nextGetCookies,
  setCookie as nextSetCookie,
} from 'cookies-next';
import type { OptionsType } from 'cookies-next/lib/types';

export const setCookie = (
  name: string,
  value: string,
  options?: OptionsType
) => {
  nextSetCookie(name, value, { ...options });
};

export const getCookie = (name: string, options?: OptionsType) => {
  return nextGetCookie(name, { ...options }) as string;
};

export const getCookies = (options?: OptionsType) => {
  return nextGetCookies({ ...options });
};

export const removeCookie = (name: string, options?: OptionsType) => {
  nextDeleteCookie(name, { ...options });
};
