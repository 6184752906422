import { ElementType, ForwardedRef, forwardRef, Ref } from 'react';
import { Element, ElementProps } from '../element';

export const _Column = <T extends ElementType = 'div'>(
  props: ElementProps<T>,
  forwardedRef: ForwardedRef<HTMLDivElement>
) => {
  return (
    <Element
      ref={forwardedRef}
      display="flex"
      flexDirection="column"
      {...props}
    />
  );
};

export const Column = forwardRef(_Column) as <T extends ElementType = 'div'>(
  props: ElementProps<T> & { ref?: Ref<HTMLDivElement> }
) => ReturnType<typeof _Column>;
