'use client';

import { isNonNil } from '@vuddy/utils';
import { isFunction } from 'lodash-es';
import { ForwardedRef, MutableRefObject, useEffect, useRef } from 'react';

export const useCombinedForwardedRef = <U>(
  ...refs: ForwardedRef<U>[]
): MutableRefObject<U | null> => {
  const targetRef = useRef<U | null>(null);

  useEffect(() => {
    refs.filter(isNonNil).forEach(ref => {
      if (isFunction(ref)) {
        ref(targetRef.current);
      } else if (isNonNil(ref)) {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};
