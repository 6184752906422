export const dispatchInputEvent = <T extends HTMLElement>(
  newValue: string,
  element: T
) => {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    element,
    'value'
  )?.set;
  nativeInputValueSetter?.call(element, newValue);
  element.dispatchEvent(new Event('input', { bubbles: true }));
};

export const dispatchChangeEvent = <T extends HTMLElement>(
  newValue: string,
  element: T
) => {
  element.dispatchEvent(new Event('change', { bubbles: true }));
};
