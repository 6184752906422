import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  format,
  isValid,
  parseISO,
} from 'date-fns';
import { ko } from 'date-fns/locale/ko';
import { isNil } from 'lodash-es';

export function getRelativeTime(utcTimeString: string): string {
  const date = parseISO(utcTimeString);
  const now = new Date();

  const secondsDiff = differenceInSeconds(now, date);
  const minutesDiff = differenceInMinutes(now, date);
  const hoursDiff = differenceInHours(now, date);
  const daysDiff = differenceInDays(now, date);
  const monthsDiff = differenceInMonths(now, date);
  const yearsDiff = differenceInYears(now, date);

  if (yearsDiff > 0) return `${yearsDiff}년 전`;
  if (monthsDiff > 0) return `${monthsDiff}개월 전`;
  if (daysDiff > 0) return `${daysDiff}일 전`;
  if (hoursDiff > 0) return `${hoursDiff}시간 전`;
  if (minutesDiff > 0) return `${minutesDiff}분 전`;
  if (secondsDiff > 10) return `${secondsDiff}초 전`;

  return '방금 전';
}

export const formatFullDate = (date: string | number | Date): string => {
  return format(date, 'yyyy.MM.dd(EEE) HH:mm:ss', {
    locale: ko,
  });
};

export const formatDayTimeDate = (date: string | number | Date): string => {
  return format(date, 'yyyy.MM.dd HH:mm', {
    locale: ko,
  });
};

export const formatDayTimeDateA = (date: string | number | Date): string => {
  return format(date, 'yyyy.MM.dd HH:mm a', {
    locale: ko,
  });
};

export const formatDayDate = (date: string | number | Date): string => {
  return format(date, 'yyyy.MM.dd', {
    locale: ko,
  });
};

export const formatInputDateTime = (date: string | number | Date): string => {
  return format(date, "yyyy-MM-dd'T'HH:mm", {
    locale: ko,
  });
};

export const formatRangeDate = (date?: string | number | Date): string => {
  if (isNil(date)) return '';
  return format(date, 'yyyy.MM.dd HH:mm', {
    locale: ko,
  });
};

export const formatInputDate = (date: string | number | Date): string => {
  return format(date, 'yyyy-MM-dd', {
    locale: ko,
  });
};

export const isValidDate = (value?: string | number | Date): value is Date => {
  return isValid(value);
};

export const formatDateTimeShort = (date: string | number | Date): string => {
  return format(new Date(date), 'MM/dd HH:mm', { locale: ko });
};
