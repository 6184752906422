import { ElementType, ForwardedRef, forwardRef, Ref } from 'react';
import { Element, ElementProps } from '../element';

const Component = <T extends ElementType = 'div'>(
  props: ElementProps<T>,
  forwardedRef: ForwardedRef<HTMLElement>
) => {
  return (
    <Element display="flex" flexDirection="row" ref={forwardedRef} {...props} />
  );
};

export const Row = forwardRef(Component) as <T extends ElementType = 'div'>(
  props: ElementProps<T> & { ref?: Ref<HTMLElement> }
) => ReturnType<typeof Component>;
