'use client';

import { useMergeRefs } from '@floating-ui/react';
import { forwardRef, ReactNode } from 'react';
import { sprinkles } from '../sprinkles.css';
import { useDropdownContext } from './useDropdownContext';

export interface DropdownTriggerProps {
  children: ReactNode;
}

export const DropdownTrigger = forwardRef<HTMLElement, DropdownTriggerProps>(
  ({ children, ...props }, forwardedRef) => {
    const { open, refs, getReferenceProps } = useDropdownContext();
    const ref = useMergeRefs([refs.setReference, forwardedRef]);
    const dataState = open ? 'open' : 'closed';

    return (
      <div
        className={sprinkles({ display: 'inline-flex' })}
        ref={ref}
        data-state={dataState}
        {...getReferenceProps({ ...props, onClick: e => e.stopPropagation() })}>
        {children}
      </div>
    );
  }
);
