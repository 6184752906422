import { isNonNil } from './fn';

export const isServerSide = typeof window === 'undefined';

export const isClientSide = !isServerSide;

export const getClientSideUserAgent = () => {
  return isClientSide ? navigator.userAgent : '';
};

export const isAndroid = (userAgent: string) => {
  return isNonNil(userAgent.match(/Android/i));
};

export const isIOSMobile = (userAgent: string) => {
  return isNonNil(userAgent.match(/iPhone/i));
};

const isAppWebView = (userAgent: string) => {
  /* vuddy/AOS|iOS/(appVersion)/(languageCode) */
  return /vuddy\/(aos|ios)/i.test(userAgent);
};

export const isMobile = (userAgent: string) => {
  return (
    isAndroid(userAgent) || isIOSMobile(userAgent) || isAppWebView(userAgent)
  );
};

export const getOSName = () => {
  if (getClientSideUserAgent().match(/Win/i)) {
    return 'windows';
  } else if (getClientSideUserAgent().match(/Mac/i)) {
    return 'mac';
  } else if (getClientSideUserAgent().match(/Linux/i)) {
    return 'linux';
  } else if (getClientSideUserAgent().match(/Android/i)) {
    return 'android';
  } else if (getClientSideUserAgent().match(/iOS/i)) {
    return 'iOS';
  } else {
    return 'unknown';
  }
};

export const getAndroidOSVersion = (userAgent: string) => {
  const match = userAgent.toLowerCase().match(/android\s([0-9.]*)/);
  return isNonNil(match) ? match[1] : undefined;
};

export const isSafariBrowser = (userAgent: string) => {
  return /^((?!chrome|android).)*safari/i.test(userAgent);
};

export const isInAppBrowser = (userAgent: string) => {
  if (isServerSide || !isMobile(userAgent)) {
    return false;
  }

  const inAppBrowserRegex =
    /inapp|Instagram|FB_IAB|FB4A|FBAN|FBIOS|KAKAOTALK|kakaotalk\/[^1]/i;
  return inAppBrowserRegex.test(getClientSideUserAgent());
};
