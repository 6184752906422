'use client';

import { isEmpty } from 'lodash-es';
import lottie, {
  AnimationConfigWithData,
  AnimationConfigWithPath,
  AnimationItem,
  RendererType,
} from 'lottie-web';
import { useEffect, useRef } from 'react';
import * as styles from './lottie.css';

export interface ILottieProps
  extends Omit<
    Partial<
      | AnimationConfigWithPath<RendererType>
      | AnimationConfigWithData<RendererType>
    >,
    'container'
  > {
  name: string;
  animationData: object;
  direction?: 'forward' | 'reverse';
  onComplete?: VoidFunction;
  speed?: number;
}

export const Lottie = (props: ILottieProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const lottieAnimation = useRef<AnimationItem | null>(null);

  const setLottieDirection = (
    animationItem: AnimationItem,
    direction?: ILottieProps['direction']
  ) => {
    if (direction === 'reverse') {
      animationItem.goToAndStop(animationItem.getDuration(true), true);
    }

    animationItem.setDirection(direction === 'reverse' ? -1 : 1);
  };

  useEffect(() => {
    if (!ref.current || isEmpty(props.animationData)) return;

    const { onComplete = () => {}, ...params } = props;
    lottieAnimation.current = lottie.loadAnimation({
      animationData: params.animationData,
      loop: false,
      autoplay: false,
      renderer: 'svg',
      container: ref.current,
      name: params.name,
    });

    lottieAnimation.current.addEventListener('complete', onComplete);

    setLottieDirection(lottieAnimation.current, params.direction);

    if (params.loop) {
      lottieAnimation.current.loop = true;
    }

    if (params.autoplay) {
      lottieAnimation.current.play();
    }

    return () => {
      lottieAnimation.current?.destroy();
    };
  }, [props.animationData]);

  useEffect(() => {
    lottieAnimation.current?.setSpeed(props.speed ?? 1);
  }, [props.speed]);

  return <div className={styles.lottie} ref={ref} />;
};
