'use client';

import { ReactNode } from 'react';
import { useDropdown, UseDropdownOptions } from './useDropdown';
import { DropdownContext } from './useDropdownContext';

export interface DropdownProps extends UseDropdownOptions {
  children?: ReactNode;
}

export const Dropdown = ({ children, ...options }: DropdownProps) => {
  const dropdown = useDropdown(options);

  return (
    <DropdownContext.Provider value={dropdown}>
      {children}
    </DropdownContext.Provider>
  );
};
