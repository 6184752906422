'use client';

import { dispatchInputEvent } from '@vuddy/utils';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import {
  ChangeEvent,
  ForwardedRef,
  InputHTMLAttributes,
  ReactHTML,
  ReactNode,
  forwardRef,
  useState,
} from 'react';
import { ElementProps } from '../element';
import { useCombinedForwardedRef } from '../hooks/useCombinedForwardedRef';
import { IIconProps } from '../icon';

export interface InputIcon extends Pick<IIconProps, 'name' | 'size'> {
  onClick?: () => void;
}

export interface InputStyles {
  inputWrapper: (params: { disabled: boolean; error: boolean }) => string;
  input: (params: { disabled: boolean; error: boolean }) => string;
}

export interface InputProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'defaultValue' | 'value' | 'prefix'
  > {
  error?: boolean;
  initialValue?: string;
  value?: string;
  suffix?: (color: ElementProps['color']) => ReactNode;
  prefix?: (color: ElementProps['color']) => ReactNode;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  styles: InputStyles;
  ignorePattern?: string;
  as?: keyof ReactHTML;
}

export const Input = forwardRef(
  (
    {
      className,
      onChange: controlledOnChange,
      value: controlledValue,
      initialValue = '',
      error = false,
      disabled = false,
      styles,
      suffix,
      prefix,
      ignorePattern,
      ...props
    }: InputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const inputRef = useCombinedForwardedRef<HTMLInputElement>(ref);
    const [innerValue, setInnerValue] = useState(initialValue);

    const unControlledOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      setInnerValue(e.target.value);
    };

    const value = controlledValue ?? innerValue;
    const onChange = controlledOnChange ?? unControlledOnChange;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const _value = props.maxLength ? value.slice(0, props.maxLength) : value;
      const regExp = new RegExp(ignorePattern ?? '', 'g');

      if (
        _value !== value ||
        (!isEmpty(ignorePattern) && regExp.test(_value))
      ) {
        e.preventDefault();
        dispatchInputEvent(_value.replace(regExp, ''), e.target);
      } else {
        onChange(e);
      }
    };

    const iconColor: IIconProps['color'] = disabled
      ? 'disabled'
      : isEmpty(value)
        ? 'placeholder'
        : 'primary';

    return (
      <div className={styles.inputWrapper({ disabled, error })}>
        {prefix?.(iconColor)}
        <input
          className={clsx(styles.input({ disabled, error }), className)}
          {...props}
          ref={inputRef}
          disabled={disabled}
          value={value}
          onChange={handleChange}
        />
        {suffix?.(iconColor)}
      </div>
    );
  }
);
