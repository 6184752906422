'use client';

import { getClientSideUserAgent, isMobile, isNonNil } from '@vuddy/utils';
import clsx from 'clsx';
import { isNil } from 'lodash-es';
import {
  forwardRef,
  KeyboardEvent,
  TextareaHTMLAttributes,
  useEffect,
} from 'react';
import { useCombinedForwardedRef } from '../hooks';
import * as styles from './textarea.css';

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  maxLines?: number;
  onEnter?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { onEnter, autoFocus = false, defaultValue, maxLines, ...props },
    forwardedRef
  ) => {
    const ref = useCombinedForwardedRef<HTMLTextAreaElement>(forwardedRef);

    const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
      requestAnimationFrame(() => {
        const lineHeight = parseFloat(getComputedStyle(textarea).lineHeight);
        const paddingTop = parseFloat(getComputedStyle(textarea).paddingTop);
        const paddingBottom = parseFloat(
          getComputedStyle(textarea).paddingBottom
        );
        const paddingY = paddingTop + paddingBottom;

        const borderTop = parseFloat(getComputedStyle(textarea).borderTopWidth);
        const borderBottom = parseFloat(
          getComputedStyle(textarea).borderBottomWidth
        );
        const borderWidthY = borderTop + borderBottom;

        textarea.style.height = 'auto';
        if (isNonNil(maxLines)) {
          const maxHeight = lineHeight * (maxLines ?? 3);
          textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight) + paddingY + borderWidthY}px`;

          if (textarea.scrollHeight > maxHeight) {
            textarea.style.overflowY = 'auto';
          } else {
            textarea.style.overflowY = 'hidden';
          }
        } else {
          textarea.style.height = `${textarea.scrollHeight + paddingY + borderWidthY}px`;
        }
      });
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (
        !isMobile(getClientSideUserAgent()) &&
        !e.nativeEvent.isComposing &&
        e.key === 'Enter' &&
        !e.shiftKey
      ) {
        e.preventDefault();
        onEnter?.(e);
      }
    };

    useEffect(() => {
      const textarea = ref.current;

      if (isNil(textarea)) return;

      adjustTextareaHeight(textarea);
    }, [props.value]);

    useEffect(() => {
      const textarea = ref.current;
      if (isNonNil(textarea) && autoFocus) {
        textarea.select();
      }
    }, [autoFocus]);

    return (
      <textarea
        {...props}
        defaultValue={defaultValue}
        className={clsx(styles.textarea, props.className)}
        ref={ref}
        rows={1}
        onKeyDown={handleKeyDown}
        maxLength={300}
      />
    );
  }
);
