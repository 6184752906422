import { isNonNil } from '@vuddy/utils';
import clsx from 'clsx';
import { omit, pick } from 'lodash-es';
import { sprinkles, type Sprinkles } from './sprinkles.css';
import { TypographyKey, typography } from './theme/theme.css';

export interface Atoms extends Sprinkles {
  className?: string | string[];
  typography?: TypographyKey;
}

export const atoms = (atoms: Atoms) => {
  const { className, typography: typographyKey, ...rest } = atoms;
  const sprinklesClassNames = sprinkles(rest);

  return clsx(
    sprinklesClassNames,
    className,
    isNonNil(typographyKey) ? typography[typographyKey] : undefined
  );
};

const keys = Array.from(sprinkles.properties.keys());

export const extractAtoms = <P extends object>(props: P) => {
  return {
    atomProps: pick(props, keys),
    componentProps: omit(props, keys),
  };
};
