import {
  compressToEncodedURIComponent,
  decompressFromEncodedURIComponent,
} from 'lz-string';

export function compressData<T>(data: T): string {
  return compressToEncodedURIComponent(JSON.stringify(data));
}

export function decompressData<T>(compressed: string): T {
  return JSON.parse(decompressFromEncodedURIComponent(compressed)) as T;
}
