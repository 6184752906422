import { isNil } from 'lodash-es';
import { createContext, useContext } from 'react';
import { UseTooltipReturn } from './useTooltip';

export type TooltipContextType = UseTooltipReturn;

export const TooltipContext = createContext<TooltipContextType>(
  {} as TooltipContextType
);

export const useTooltipContext = (): TooltipContextType => {
  const context = useContext(TooltipContext);

  if (isNil(context)) {
    throw new Error('<Tooltip /> 컴포넌트로 감싸세요.');
  }

  return context;
};
