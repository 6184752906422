import {
  FloatingArrow,
  FloatingPortal,
  useMergeRefs,
  useTransitionStyles,
} from '@floating-ui/react';
import { jsonAssignInlineVars } from '@vuddy/utils';
import clsx from 'clsx';
import { isNil } from 'lodash-es';
import { CSSProperties, HTMLProps, forwardRef } from 'react';
import { useZIndex } from '../hooks';
import { SurfaceColorToken, TypoColorToken, theme } from '../theme/theme.css';
import * as tooltipStyles from './tooltip.css';
import { useTooltipContext } from './useTooltipContext';

export const TooltipContent = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement> & {
    color?: TypoColorToken;
    bgColor?: SurfaceColorToken;
    arrow?: boolean;
    arrowStyle?: CSSProperties;
    fullWidth?: boolean;
    styles?: string;
  }
>(
  (
    {
      color = theme.text.whitePrimary,
      bgColor = theme.surface.black,
      arrow = false,
      arrowStyle,
      fullWidth = false,
      children,
      ...props
    },
    forwardedRef
  ) => {
    const { refs, strategy, x, y, context, getFloatingProps } =
      useTooltipContext();
    const { isMounted, styles: transitionStyles } =
      useTransitionStyles(context);
    const ref = useMergeRefs([refs.setFloating, forwardedRef]);
    const zIndex = useZIndex(isMounted);

    return (
      <FloatingPortal>
        {isMounted && (
          <div
            ref={ref}
            className={clsx(
              tooltipStyles.contentWrapper({
                fullWidth,
              }),
              props.styles
            )}
            style={jsonAssignInlineVars({
              [tooltipStyles.backgroundColorVar]: bgColor,
              [tooltipStyles.colorVar]: color,
              [tooltipStyles.positonVar]: strategy,
              [tooltipStyles.visibilityVar]: isNil(x) ? 'hidden' : 'visible',
              [tooltipStyles.topVar]: (y ?? 0) + 'px',
              [tooltipStyles.leftVar]: (x ?? 0) + 'px',
              [tooltipStyles.zIndexVar]: zIndex,
              ...transitionStyles,
            })}
            {...getFloatingProps(props)}>
            {children}
            {arrow && (
              <div className={tooltipStyles.arrowWrapper} style={arrowStyle}>
                <FloatingArrow
                  context={context}
                  width={11}
                  fill={bgColor}
                  style={{
                    position: 'absolute',
                    width: '11px',
                    height: '11px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                />
              </div>
            )}
          </div>
        )}
      </FloatingPortal>
    );
  }
);
