'use client';

import { isClientSide } from '@vuddy/utils';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { Overlay } from './Overlay';
import type {
  OverlayComponent,
  OverlayContextType,
  OverlayItem,
} from './types';

export const OverlayContext = createContext<OverlayContextType>(
  {} as OverlayContextType
);

export const OverlayProvider = ({
  children,
  maxWidth = '100%',
}: {
  children: ReactNode;
  maxWidth?: string;
}) => {
  const [overlays, setOverlays] = useState<OverlayItem[]>([]);

  const openOverlay = ({
    allowDuplicate = false,
    ...overlayComponent
  }: OverlayComponent): number => {
    const id = Date.now();
    setOverlays(prev => {
      const openedINdex = prev.findIndex(openedOverlay => {
        return openedOverlay.Component.component == overlayComponent.component;
      });
      const isSameOverlayOpened = openedINdex > -1;
      if (allowDuplicate || !isSameOverlayOpened) {
        return [...prev, { id, Component: overlayComponent }];
      } else {
        return prev;
      }
    });
    return id;
  };

  const openModal = (
    overlayComponent: Omit<OverlayComponent, 'isBottomSheet'>
  ) => {
    openOverlay({
      ...overlayComponent,
      isBottomSheet: false,
      props: {
        ...overlayComponent.props,
        style: {
          overlay: {
            ...overlayComponent.props?.style?.overlay,
          },
          container: {
            ...overlayComponent.props?.style?.container,
            maxWidth,
          },
        },
      },
    });
  };

  const openBottomSheet = (
    overlayComponent: Omit<OverlayComponent, 'isBottomSheet'>
  ) => {
    openOverlay({
      ...overlayComponent,
      isBottomSheet: true,
      props: {
        ...overlayComponent.props,
        style: {
          overlay: {
            maxWidth: maxWidth,
          },
        },
      },
    });
  };

  const closeOverlay = (id: number) => {
    setOverlays(prev => {
      const overlayToClose = prev.find(overlay => overlay.id === id);
      if (overlayToClose?.Component.props?.onCloseAction) {
        overlayToClose.Component.props.onCloseAction();
      }
      return prev.filter(overlay => overlay.id !== id);
    });
  };

  useEffect(() => {
    if (overlays.length > 0) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [overlays]);

  return (
    <OverlayContext.Provider
      value={{ openBottomSheet, openModal, closeOverlay, overlays }}>
      {children}
      {isClientSide &&
        overlays.map(({ id, Component }, index) => (
          <Overlay
            key={id}
            id={id}
            Component={Component}
            isActive={overlays.length - 1 === index}
          />
        ))}
    </OverlayContext.Provider>
  );
};
