import { ReactNode, memo } from 'react';
import { IUseTooltipOptions, useTooltip } from './useTooltip';
import { TooltipContext } from './useTooltipContext';

export interface ITooltipProps extends IUseTooltipOptions {
  children: ReactNode;
}

export const Tooltip = memo(({ children, ...options }: ITooltipProps) => {
  const tooltip = useTooltip(options);

  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
});
