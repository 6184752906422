'use client';

import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useState } from 'react';
import { ClearButton } from '../clearButton';
import { ElementProps } from '../element';
import { Column } from '../flex';
import * as styles from './accordion.css';

export interface AccordionProps {
  initialOpen?: boolean;
  header: (isOpen: boolean) => ReactNode;
  children: ReactNode;
  gap?: ElementProps['gap'];
}

export const Accordion = ({
  initialOpen = false,
  header,
  children,
  gap = 'gap_xl',
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  return (
    <Column gap={gap} width="100%">
      <ClearButton onClick={() => setIsOpen(isOpen => !isOpen)} fullWidth>
        <header>{header(isOpen)}</header>
      </ClearButton>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            className={styles.content}
            variants={{
              open: { opacity: 1, height: 'auto' },
              close: { opacity: 0, height: 0 },
            }}
            initial="close"
            animate="open"
            exit="close"
            transition={{ duration: 0.3, ease: 'easeOut' }}>
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </Column>
  );
};
