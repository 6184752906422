import { ButtonHTMLAttributes, ReactNode } from 'react';
import { ButtonVariants, button } from './button.css';

export type ButtonProps = ButtonVariants & {
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  children?: ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  /**
   * Optional click handler
   */
  onClick?: () => void;
};

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  type = 'button',
  variant = 'solidPrimary',
  size = 'medium',
  fullWidth = false,
  children,
  ...props
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={button({
        variant,
        size,
        fullWidth,
      })}
      {...props}>
      {children}
    </button>
  );
};
