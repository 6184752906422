import { RefObject, useEffect, useRef } from 'react';

interface UseBottomSheetSwipeActionsOptions {
  duration: number;
  overlayRef: RefObject<HTMLDivElement>;
  handleRef: RefObject<HTMLDivElement>;
  contentRef: RefObject<HTMLDivElement>;
  onTouchStart?: VoidFunction;
  onTouchEnd?: VoidFunction;
  onSwipeClose: VoidFunction;
}

const SWIPE_CLOSE_RATIO = 0.3;

export const useBottomSheetSwipeActions = ({
  duration,
  overlayRef,
  handleRef,
  contentRef,
  onTouchStart = () => {},
  onTouchEnd = () => {},
  onSwipeClose,
}: UseBottomSheetSwipeActionsOptions) => {
  const initialTouchPointerY = useRef<number | null>(null);
  const currentTouchPointerY = useRef<number | null>(null);
  const removeListeners = useRef<(() => void) | null>(null);

  const clearTransitionStyles = () => {
    if (contentRef.current) {
      contentRef.current.style.transitionDuration = '';
      contentRef.current.style.transitionProperty = '';
    }
  };

  const restoreTransitionStyles = () => {
    if (contentRef.current) {
      contentRef.current.style.transitionDuration = `${duration}ms`;
      contentRef.current.style.transitionProperty = 'transform';
    }
  };

  const clearTransformStyles = () => {
    if (contentRef.current) {
      contentRef.current.style.transform = '';
    }
  };

  const getFirstTouchPointerY = (e: TouchEvent) => {
    return e.touches[0].clientY;
  };

  const getNextTranslateYOffset = (e: TouchEvent) => {
    const vector =
      getFirstTouchPointerY(e) - (initialTouchPointerY.current ?? 0);
    return Math.max(0, vector);
  };

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      initialTouchPointerY.current = getFirstTouchPointerY(e);
      clearTransitionStyles();
      onTouchStart();
    };

    const handleTouchMove = (e: TouchEvent) => {
      e.preventDefault();
      if (contentRef.current) {
        contentRef.current.style.transform = `translateY(${getNextTranslateYOffset(e)}px)`;
      }
      currentTouchPointerY.current = getFirstTouchPointerY(e);
    };

    const isSwiped = () => {
      return (
        currentTouchPointerY.current !== null &&
        initialTouchPointerY.current !== null
      );
    };

    const isSwipedOverCloseLimit = () => {
      if (
        !contentRef.current ||
        !currentTouchPointerY.current ||
        !initialTouchPointerY.current
      )
        return false;
      const closeLimit = contentRef.current.clientHeight * SWIPE_CLOSE_RATIO;
      return (
        currentTouchPointerY.current - initialTouchPointerY.current >=
        closeLimit
      );
    };

    const handleTouchEnd = () => {
      restoreTransitionStyles();
      if (isSwiped()) {
        if (isSwipedOverCloseLimit()) {
          onSwipeClose();
        } else {
          clearTransformStyles();
        }
      }
      onTouchEnd();
      initialTouchPointerY.current = null;
      currentTouchPointerY.current = null;
    };

    const bindEvents = () => {
      const elements = [overlayRef.current, handleRef.current].filter(Boolean);
      if (contentRef.current) {
        contentRef.current.addEventListener('touchmove', e =>
          e.stopPropagation()
        );
      }
      elements.forEach(element => {
        if (element) {
          element.addEventListener('touchstart', handleTouchStart);
          element.addEventListener('touchmove', handleTouchMove);
          element.addEventListener('touchend', handleTouchEnd);
        }
      });

      removeListeners.current = () => {
        if (contentRef.current) {
          contentRef.current.removeEventListener('touchmove', e =>
            e.stopPropagation()
          );
        }
        elements.forEach(element => {
          if (element) {
            element.removeEventListener('touchstart', handleTouchStart);
            element.removeEventListener('touchmove', handleTouchMove);
            element.removeEventListener('touchend', handleTouchEnd);
          }
        });
      };
    };

    bindEvents();

    return () => {
      removeListeners.current?.();
      removeListeners.current = null;
    };
  }, [duration, onTouchStart, onTouchEnd]);
};
