import { usePathname, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useRef } from 'react';

export const useRouteChangeHandler = (handler: () => void) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const previousPathnameRef = useRef(pathname);
  const previousSearchParamsRef = useRef(searchParams);

  const handleRouteChange = useCallback(() => {
    const currentSearchParamsString = searchParams.toString();
    const previousSearchParamsString =
      previousSearchParamsRef.current.toString();

    if (
      pathname !== previousPathnameRef.current ||
      currentSearchParamsString !== previousSearchParamsString
    ) {
      handler();
      previousPathnameRef.current = pathname;
      previousSearchParamsRef.current = searchParams;
    }
  }, [pathname, searchParams, handler]);

  useEffect(() => {
    handleRouteChange();
  }, [handleRouteChange]);
};
