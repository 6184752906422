'use client';

import { InputHTMLAttributes, useState } from 'react';
import * as styles from './checkbox.css';

export interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (checked: boolean) => void;
  children: React.ReactNode;
}

export const Checkbox = ({
  id,
  checked: controlledChecked,
  disabled,
  children,
  defaultChecked = false,
  onChange: controlledOnChange,
  ...props
}: CheckboxProps) => {
  const [unControlledChecked, uncontrolledSetChecked] =
    useState(defaultChecked);

  const checked = controlledChecked ?? unControlledChecked;
  const onChange = controlledOnChange ?? uncontrolledSetChecked;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <label
      className={styles.label}
      htmlFor={id}
      role="checkbox"
      aria-checked={checked}
      aria-disabled={disabled}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
        className={styles.hiddenInput}
        {...props}
      />
      {children}
    </label>
  );
};
