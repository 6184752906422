import { ButtonHTMLAttributes, ReactNode } from 'react';
import { tabButton } from './tabButton.css';

export type TabButtonProps = {
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  children?: ReactNode;
  enabled: boolean;
  onClick?: () => void;
};

export const TabButton = ({
  type = 'button',
  children,
  enabled,
  onClick,
}: TabButtonProps) => {
  return (
    <button
      type={type}
      className={tabButton({
        enabled,
      })}
      onClick={onClick}>
      {children}
    </button>
  );
};
