'use client';

import {
  FloatingPortal,
  useMergeRefs,
  useTransitionStyles,
} from '@floating-ui/react';
import { isNonNil } from '@vuddy/utils';
import { ForwardedRef, ReactNode, Ref, forwardRef } from 'react';
import { useDropdownContext } from './useDropdownContext';

type Option<T> = T & {
  id: string;
  label: ReactNode;
};

export interface DropdownContentProps<T> {
  className?: string;
  options: Option<T>[];
  onClick: (id: string) => void;
  renderOption?: (option: Option<T>) => ReactNode;
}

const Component = <T,>(
  {
    className,
    options,
    onClick,
    renderOption,
    ...props
  }: DropdownContentProps<T>,
  forwardedRef: ForwardedRef<HTMLDivElement>
) => {
  const { refs, context, floatingStyles, setOpen, getFloatingProps } =
    useDropdownContext();
  const ref = useMergeRefs([refs.setFloating, forwardedRef]);
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: 300,
  });

  return (
    <FloatingPortal>
      {isMounted && (
        <div
          className={className}
          ref={ref}
          style={{
            ...floatingStyles,
            ...transitionStyles,
          }}
          {...getFloatingProps(props)}>
          {options.map(option => (
            <div
              key={option.id}
              onClick={e => {
                onClick(option.id);
                setOpen(false);
                e.stopPropagation();
              }}>
              {isNonNil(renderOption) ? renderOption(option) : option.label}
            </div>
          ))}
        </div>
      )}
    </FloatingPortal>
  );
};

export const DropdownContent = forwardRef(Component) as <T>(
  props: DropdownContentProps<T> & { ref?: Ref<HTMLDivElement> }
) => ReturnType<typeof Component>;
