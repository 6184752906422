import {
  ComponentPropsWithoutRef,
  ElementType,
  ForwardedRef,
  forwardRef,
  ReactNode,
  Ref,
} from 'react';
import { Atoms, atoms, extractAtoms } from '../atom';

type AsProps<T extends ElementType> = ComponentPropsWithoutRef<T> & {
  as?: T;
};

export type ElementProps<T extends ElementType = ElementType> = AsProps<T> &
  Atoms & {
    children?: ReactNode;
  };

const Component = <T extends ElementType = 'div'>(
  props: ElementProps<T>,
  forwardedRef: ForwardedRef<HTMLElement>
) => {
  const {
    as: Component = 'div',
    className,
    children,
    typography,
    ...rest
  } = props;
  const { atomProps, componentProps } = extractAtoms(
    rest as Omit<ElementProps<T>, 'children'>
  );
  const atomClassName = atoms({ className, typography, ...atomProps });

  return (
    <Component
      {...componentProps}
      className={atomClassName}
      ref={forwardedRef as ForwardedRef<HTMLDivElement>}>
      {children}
    </Component>
  );
};

export const Element = forwardRef(Component) as <T extends ElementType = 'div'>(
  props: ElementProps<T> & { ref?: Ref<HTMLElement> }
) => ReturnType<typeof Component>;
